import React, {useEffect, useState} from "react";
import KeepScroll from "../../lib/KeepScroll";
import Dialog from "../../lib/Dialog";
import useActiveState from "../../lib/useActiveState";
import general from "../../general";
import moment from "moment";
import {useInterval} from "ahooks";
import {useHistory, useLocation} from "react-router-dom";

//一重礼
const onedays = [
    {
        name: '签到',
        info: '留下你的专属足迹',
        class: 'sign',
        pic: 'qd',
        taskname: 'sign'
    },
    {
        name: '登录游戏',
        info: '登录任意游戏即可完成任务',
        class: 'login_game',
        pic: 'game',
        taskname: 'login_game'
    },
    {
        name: '游戏评价',
        info: '发布游戏评价，说出你的感受',
        class: 'comments',
        pic: 'pj',
        taskname: 'comments'
    },
];

//二重礼
const twodays = [
    {
        name: '签到',
        info: '留下你的专属足迹',
        class: 'sign',
        pic: 'qd',
        taskname: 'sign'
    },
    {
        name: '登录游戏',
        info: '登录任意游戏即可完成任务',
        class: 'login_game',
        pic: 'game',
        taskname: 'login_game'
    },
    {
        name: '游戏评价',
        info: '发布游戏评价，说出你的感受',
        class: 'comments',
        pic: 'pj',
        taskname: 'comments'
    },
    {
        name: '累计现金充值1元',
        info: '花点小钱，体验更多游戏乐趣',
        class: 'amount1',
        pic: 'lc',
        taskname: 'amount1'
    },
];

//三重礼
const threedays = [
    {
        name: '签到',
        info: '留下你的专属足迹',
        class: 'sign',
        pic: 'qd',
        taskname: 'sign'
    },
    {
        name: '登录游戏',
        info: '登录任意游戏即可完成任务',
        class: 'login_game',
        pic: 'game',
        taskname: 'login_game'
    },
    {
        name: '游戏评价',
        info: '发布游戏评价，说出你的感受',
        class: 'comments',
        pic: 'pj',
        taskname: 'comments'
    },
    {
        name: '累计现金充值10元',
        info: '花点小钱，体验更多游戏乐趣',
        class: 'amount10',
        pic: 'lc',
        taskname: 'amount10'
    },
];

export default props => {
    const location = useLocation();
    const history = useHistory();
    const [bouchStatus, setBouchStatus] = useState(false); //选择游戏状态
    const [vouchBox, setVouchBox] = useState(false);//领取弹框
    const [datas, setDatas] = useState([]);
    const [user, setUser] = useActiveState('user');
    const [oday, setOday] = useState([]);
    const [tday, setTday] = useState([]);
    const [sday, setSday] = useState([]);
    const [current, setCurrent] = useState(0);
    const [giftList, setGiftList] = useState([]);
    const [packids, setPackids] = useState([]);//多个游戏ID
    const [activeEndTime, setActiveEndTime] = useState(0);
    const [nextStartTime, setNextStartTime] = useState(0);
    const [routerIndex, setRouterIndex] = useState(-1);
    const [ruleState, setRuleState] = useState(false);

    const load = () => {
        Dialog.loading();
        general.axios.post('/user/ActiveFlyback/getIndexData')
          .finally(() => {
            Dialog.close();
          })
          .then(response => {
            let {status, data} = response.data;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
              return;
            }
            setDatas(data);
            setOday(data.day1);
            setTday(data.day2);
            setSday(data.day3);
            setGiftList(data.gift_list);
            setActiveEndTime(data.active_end_time);
            setNextStartTime(data.next_start_time);
            setCurrent(data.day);
          })
          .catch(err => {
              Dialog.error(err.message);
            });
    }
    
    useEffect(() => {
        load();
    }, []);

    // console.log(datas);

    
    const receive = (number) => {
        if(!general.isLogin()) {
            history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
            return false;
        }
        // setReceDay(number);
        let status;
        if(number == 1) {
            status = oday.status;
        } else if(number == 2) {
            status = tday.status;
        } else if(number == 3) {
            status = sday.status;
        }

        // console.log(receDay);

        if(status == 1) {
            if(number == 1) {
                setVouchBox(true);
            } else if(number == 2) {
                giftRecelve(2);
            } else if(number == 3) {
                giftRecelve(3);
            }
            
        }
        // Dialog.alert("test");
    }

    // console.log(routerIndex);

    const taskReceice = (item) => {
        if(!general.isLogin()) {
            history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
            return false;
        }
        let status;
        if(datas.day == 1){
            status = oday[item.taskname];
        } else if(datas.day == 2) {
            status = tday[item.taskname];
        } else if(datas.day == 3) {
            status = sday[item.taskname];
        }
        // console.log(status);
        if(status == false) {
            if(item.taskname == 'sign') {
                general.history.push('/task/signdata');
            } else if(item.taskname == 'login_game') {
                // Dialog.alert("aaaa");
                // setBouchStatus(true);
            } else if(item.taskname == 'comments') {
                // Dialog.alert("aaaa");
            } else if(item.taskname == 'amount1') {
                // Dialog.alert("aaaa");
            } else if(item.taskname == 'amount10') {
                // Dialog.alert("aaaa");
            } 
        }
        // console.log(taska[item.taskname]);
    }

    //多个游戏领取
    const giftRecelve = (key) => {
        if(!general.isLogin()) {
            history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
            return false;
        }
        if(key == 1) {
            if(packids.length < 1){
                Dialog.info("请选择要领取得游戏礼包");
                return false;
            }
        }
        Dialog.loading();
        
        general.axios.post('/user/ActiveFlyback/receive', {day:key, pack_ids:packids.toString()})
        .finally(() => {
            Dialog.close();
        })
        .then(res => {
            let {status, data} = res.data;
            
            if (!status.succeed) {
                Dialog.info(status.error_desc);
            } else {
                if(key == 1) {
                    Dialog.info("领取成功");
                    setPackids("");
                    setBouchStatus(false);
                } else {
                    setVouchBox(true);
                }
            }
        })
        .catch(err => {
            Dialog.error(err.message);
        });
    }

    // const addPack = (item) => {
    //     let pack = packids.indexOf(item);

    //     if(pack != -1) {

    //     } else {
    //         setPackids([...packids, item]);
    //     }
    // }

    const addPack = (item) => {
        if(item.is_received) {
            return false;
        }
        let packid = item.packid;
        let pack = packids.indexOf(packid);

        if(pack != -1) {
            
        } else {
            setPackids([...packids, packid]);
        }
    }

    //倒计时
    useInterval(() => {
        if(activeEndTime) {
            
            setActiveEndTime(activeEndTime-1);
        }
        if(nextStartTime) {
            setNextStartTime(nextStartTime-1);
        }
    }, 1000);

    // console.log(activeEndTime % 60);
    // console.log(activeEndTime % 3600 / 60 );
    // console.log(activeEndTime / 3600);

  return <div className="regression-index">
      <div className="all">
        <div className="rule" onClick={()=>setRuleState(true)}>活动规则</div>
        <div className="first-part">
            <img src="/resources/party/regression-top.png" />
            <div className="end_time">
                距离活动结束剩余：<span className="active_time">
                    {
                        activeEndTime ?
                        Math.floor(activeEndTime / 3600) + ':' + Math.floor(activeEndTime % 3600 / 60) + ':' + Math.floor(activeEndTime % 60)
                        : null
                    }
                    {/* {activeEndTime ? moment(activeEndTime * 1000).format('HH:mm:ss') : null} */}
                </span>
            </div>
            <div className="gift-section">
                <div className="gift-bag" style={{backgroundImage: 'url(/resources/party/regression-bg1.png)'}}>
                    <p>活动期间，每24小时开启一个礼包</p>
                    <p>
                        距离下次礼包开启还有：<span className="next_time">
                            {
                                nextStartTime ?
                                Math.floor(nextStartTime / 3600) + ':' + Math.floor(nextStartTime % 3600 / 60) + ':' + Math.floor(nextStartTime % 60)
                                : null
                            }
                            {/* {nextStartTime ? moment(nextStartTime * 1000).format('HH:mm:ss') : null} */}
                        </span>
                    </p>
                    <ul>
                        <li>
                            <div className="bag-img">
                                {oday ? <>
                                {(()=>{
                                    if(oday.status == 0) {
                                        return <img src="/resources/party/regession-lb-two.png" className="close-img"/>
                                    } else {
                                        return <img src="/resources/party/regession-lb-one.png" className="open-img"/>
                                    }
                                })()}</>
                                :null}
                            </div>
                            <div className="bag-text">
                                一重礼
                            </div>
                            <div className={"lg-button " + (oday ? oday.status == 1 ? " " : "already-lq" :"")} onClick={()=> {receive(1);setRouterIndex(1)}}>
                                {(()=>{
                                    if(oday.status == 0) {
                                        return "未完成"
                                    } else if(oday.status == 1) {
                                        return "未领取"
                                    } else if(oday.status == 2) {
                                        return "已领取"
                                    }
                                })()}
                            </div>
                        </li>
                        <li>
                            <div className="bag-img">
                                {tday ? <>
                                {(()=>{
                                    if(tday.status == 0) {
                                        return <img src="/resources/party/regession-lb-two.png" className="close-img"/>
                                    } else {
                                        return <img src="/resources/party/regession-lb-one.png" className="open-img"/>
                                    }
                                })()}</>
                                :null}
                            </div>
                            <div className="bag-text">
                                二重礼
                            </div>
                            <div className={"lg-button " + (tday ? tday.status == 1 ? " " : "already-lq" :"")} onClick={()=>{receive(2);setRouterIndex(2)}}>
                                {(()=>{
                                    if(datas.day < 2) {
                                        return "未开启";
                                    } else if(tday.status == 0) {
                                        return "未完成";
                                    } else if(tday.status == 1) {
                                        return "未领取";
                                    } else if(tday.status == 2) {
                                        return "已领取";
                                    }
                                })()}
                            </div>
                        </li>
                        <li>
                            <div className="bag-img">
                                {sday ? <>
                                {(()=>{
                                    if(sday.status == 0) {
                                        return <img src="/resources/party/regession-lb-two.png" className="close-img"/>
                                    } else {
                                        return <img src="/resources/party/regession-lb-one.png" className="open-img"/>
                                    }
                                })()}</>
                                :null}
                            </div>
                            <div className="bag-text">
                                三重礼
                            </div>
                            <div className={"lg-button " + (sday ? sday.status == 1 ? " " : "already-lq" :"")} onClick={()=>{receive(3);setRouterIndex(3)}}>
                                {(()=>{
                                    if(datas.day < 3) {
                                        return "未开启";
                                    } else if(sday.status == 0) {
                                        return "未完成";
                                    } else if(sday.status == 1) {
                                        return "未领取";
                                    } else if(sday.status == 2) {
                                        return "已领取";
                                    }
                                })()}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div className="second-part">
            {datas?
            <>
            {(()=>{
                if(datas.day == 3) {
                    return <div className="first-section">
                        <div className="hg1">
                            <img src="/resources/party/regession-zrw2.png" />
                        </div>
                        <div className="desc" style={{backgroundImage: 'url(/resources/party/regession-hx.png)'}}>
                            <p className="title">【三重礼】</p>
                            <ul>
                                {threedays.map((item) => <li>
                                    <div className="text" >
                                        <div className="logo">
                                            <img src={"/resources/party/regession-" + item.pic + ".png"} />
                                        </div>
                                        <div className="describe">
                                            <p>
                                                <span className="name">{item.name}</span>
                                            </p>
                                            <p>
                                                {item.info}
                                            </p>
                                        </div>
                                        <div className="discount">
                                            <div className={"load-button " + (sday ? sday[item.taskname] ? ' alreadly-lq' : '' : '')}>
                                                {
                                                    sday ? sday[item.taskname] ? ' 已完成' : item.taskname == 'sign' ? '前往' : '未完成' : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                        <div className="hg2">
                            <img src="/resources/party/regession-bottom.png" />
                        </div>
                    </div>
                } else if(datas.day == 2) {
                    return <div className="second-section">
                        <div className="hg1">
                            <img src="/resources/party/regession-zrw2.png" />
                        </div>
                        <div className="desc" style={{backgroundImage: 'url(/resources/party/regession-hx.png)'}}>
                            <p className="title">【二重礼】</p>
                            <ul>
                                {twodays.map((item) => <li>
                                    <div className="text" onClick={() => taskReceice(item)}>
                                        <div className="logo">
                                            <img src={"/resources/party/regession-" + item.pic + ".png"} />
                                        </div>
                                        <div className="describe">
                                            <p>
                                                <span className="name">{item.name}</span>
                                            </p>
                                            <p>
                                                {item.info}
                                            </p>
                                        </div>
                                        <div className="discount">
                                            <div className={"load-button " + (tday ? tday[item.taskname] ? ' alreadly-lq' : '' : '')}>
                                                {
                                                    tday ? tday[item.taskname] ? ' 已完成' : item.taskname == 'sign' ? '前往' : '未完成' : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                        <div className="hg2">
                            <img src="/resources/party/regession-bottom.png" />
                        </div>
                    </div>
                } else if(datas.day == 1) {
                    return <div className="third-section">
                        <div className="hg1">
                            <img src="/resources/party/regession-zrw2.png" />
                        </div>
                        <div className="desc" style={{backgroundImage: 'url(/resources/party/regession-hx.png)'}}>
                            <p className="title">【一重礼】</p>
                            <ul>
                                {onedays.map((item) => <li>
                                    <div className="text" onClick={() => taskReceice(item)}>
                                        <div className="logo">
                                            <img src={"/resources/party/regession-" + item.pic + ".png"} />
                                        </div>
                                        <div className="describe">
                                            <p>
                                                <span className="name">{item.name}</span>
                                            </p>
                                            <p>
                                                {item.info}
                                            </p>
                                        </div>
                                        <div className="discount" >
                                            <div className={"load-button " + (oday ? oday[item.taskname] ? ' alreadly-lq' : '' : '')}>
                                                {
                                                    oday ? oday[item.taskname] ? ' 已完成' : item.taskname == 'sign' ? '前往' : '未完成' : ''
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </li>)}
                            </ul>
                        </div>
                        <div className="hg2">
                            <img src="/resources/party/regession-bottom.png" />
                        </div>
                    </div>
                }
            })()}
            </>
            :null}
            
        </div>
    </div>

    {/** 选择游戏 **/}
    {bouchStatus ?
    <div className="bouch-mask">
        <div className="mask-site">
            <div className="mask-content">
                <div className="head">
                    <div className="title">一重好礼</div>
                    <div className="close" onClick={() => setBouchStatus(false)}><img src="/resources/party/couple-close.png" /></div>
                </div>
                <div className="wp">
                    <div className="czk-coins">
                        <ul>
                            <li>
                                <img src="/resources/party/regession-czk.png" />
                            </li>
                            <li>
                                <img src="/resources/party/regession-coins.png" />
                            </li>
                        </ul>
                        {/* <div>
                            <img src="/resources/party/regession-czk.png" />
                        </div>
                        <div>
                            <img src="/resources/party/regession-coins.png" />
                        </div> */}
                    </div>
                    <div className="name-title">648充值</div>
                    <div className="desc">
                        <div>请选择感兴趣的游戏（可多选），再领取充值卡礼包，充值卡的具体价值以礼包详情为准。</div>
                        <div className="tips"> <span>*</span>领取后可前往我的页面-玩家服务-我的礼包中查看兑换码。</div>
                    </div>
                    <div className="sel-list">
                        {giftList.map((item, index) => <div className={"new-week-item " + (item.is_received ? '' : packids.indexOf(item.packid) > -1 ? " checked" : "" )} onClick={() => addPack(item)}>
                            <div className="icon">
                                <img src={item.logo} />
                            </div>
                            <div className="text">
                                <div className="name">{item.gamename}</div>
                                <div className="info">{item.versioncode}&nbsp;|&nbsp;{item.how_many_play}人在玩</div>
                                <div className="info2">
                                    {
                                        item.version ? 
                                        <>
                                        {item.version.map((v, k) => <div className="tag">{v}</div>)}
                                        </>
                                        :null
                                    }
                                </div>
                            </div>
                            {
                                item.is_received ? null :
                                <div className="status"></div>
                            }
                        </div>)}
                    </div>
                </div>
                <div className="accept" onClick={()=>giftRecelve(1)}>
                    收下
                </div>
            </div>
        </div>
    </div>
    :null}

    {/** 领取弹框 **/}
    {
    vouchBox ? 
    <div className="vouch-box">
        <div className="vouch-site">
            <div className="content">
                {(()=>{
                    if(routerIndex == 2) {
                        return <img src="/resources/party/regession-two.png" />
                    } else if(routerIndex == 3) {
                        return <img src="/resources/party/regession-three.png" />
                    } else {
                        return <img src="/resources/party/regession-one.png" onClick={()=> {setBouchStatus(true);setVouchBox(false)}}/>
                    }
                })()}
            </div>
            <div className="close" onClick={() => setVouchBox(false)}>
                <img src="/resources/party/down-close.png" />
            </div>
        </div>
    </div>
    :null}

    {/** 活动规则 **/}
    {ruleState ? 
    <div className="rule-bounced">
      <div className="rule-index">
        <div className="rule-content">
          <p>1.本活动仅限活动发布日期后进入平台的老用户参加；<span style={{fontWeight:"bold"}}>玩家进入活动页面视为活动开启</span>，开始任务倒计时，<span style={{fontWeight:"bold"}}>3日后活动结束</span>；</p>
          <p>2.任务中除签到外，每日<span style={{fontWeight:"bold"}}>任务需要在当日礼包开启之后再去完成才能生效</span>，当日礼包开启之前做任务无效；</p> 
          <p>3.<span style={{fontWeight:"bold"}}>活动结束后未完成的礼包将会过期</span>，完成任务也无法获得；任务结束后活动入口及<span style={{fontWeight:"bold"}}>奖励将保留48小时</span>，超时活动页面关闭，活动奖励将无法领取；</p>
          <p>4.<span style={{fontWeight:"bold"}}>任务完成后的礼包需手动领取</span>，有效期内未领取将不再补发；</p>
          <p>5.任务内的<span style={{fontWeight:"bold"}}>累计充值仅计算现金充值</span>，包含现金充值会员、平台币、游戏，其他类型充值均不计算在内；</p>
          <p>活动解释权归麦游网络所有</p>
        </div>
        <div className="close" style={{backgroundImage:"url(/resources/party/close.png)"}} onClick={()=>{setRuleState(false)}}></div>
      </div>
    </div>
    : null}

  </div>
};