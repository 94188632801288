import React, {useEffect, useState} from "react";
import {NavLink} from "../../../lib/Navigation";
import {useLockFn} from "ahooks";
import general from "../../../general";
import Dialog from "../../../lib/Dialog";
import useActiveState from "../../../lib/useActiveState";
import {useHistory, useLocation} from "react-router-dom";
import URI from "urijs";

const top_task = [
    {
      name: '3元无门槛券',
      info: '游戏充值直接抵扣3元  畅玩无忧',
      type: 2,
      class: 'sign',
      taskname: 'balance'
    },
];


const tasks = [
    {
      name: '签到',
      reward: '6元代金券 满30元可用',
      type: 3,
      class: 'sign',
      taskname: 'sign'
    },
    {
      name: '登录游戏',
      reward: '10元代金券 满98元可用',
      type: 4,
      info: '给自己取个响亮的名字吧',
      class: 'login',
      taskname: 'login_game'
    },
    {
      name: '累计充值1元',
      reward: '20元代金券 满128元可用',
      type: 5,
      class: 'recharge',
      taskname: 'recharge'
    },
];

export default props => {
    const location = useLocation();
    const history = useHistory();
    const [datas, setDatas] = useState([]);
    const [data, setData] = useState();
    // useEffect(() => {
    //     !data && setData(datas.day2_info);
    // }, [datas]);

    const [user, setUser] = useActiveState('user');

    const load = () => {
        if(!general.isLogin()) {
            return false;
        }
        Dialog.loading();
        general.axios.post('/user/active2203/getIndexData')
          .finally(() => {
            Dialog.close();
          })
          .then(response => {
            let {status, data} = response.data;
            if (!status.succeed) {
              Dialog.error(status.error_desc);
              return;
            }
            setDatas(data);
            setData(data.day2_info);
          })
          .catch(err => {
            Dialog.error(err.message);
          });
    }

    useEffect(() => {
        load();
    }, [props.active]);


    const receive_two = (type)=>{
        if(!general.isLogin()) {
            history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
            return false;
        }
        let name = type.taskname;
        if(data[name] == 1) {
            if(type.taskname == 'balance') {
                if(datas.day < 2) {
                    Dialog.info("活动未开启");
                    return false;
                }
            }
            Dialog.loading();
            general.axios.post('/user/active2203/receive', {type:type.type})
            .finally(() => {
                Dialog.close();
            })
            .then(res => {
                let {status, data} = res.data;
                
                if (!status.succeed) {
                    Dialog.info(status.error_desc);
                } else {
                    Dialog.info("领取成功");
                    load();
                }
            })
            .catch(err => {
                Dialog.error(err.message);
            });
        } else if(data[name] == 0) {
            if(datas.day < 2) {
                Dialog.info('活动未开启');
            } else if (type.taskname === 'sign') {
                general.history.push('/task/signdata');
            } else if (type.taskname === 'login_game') {
                Dialog.alert('温馨提示', '畅玩任何一款游戏均可领取金币哦');
            } else if (type.taskname === 'recharge') {
                Dialog.info("仅计算现金/平台币充值游戏");
            }
        }
    }

    return <div className="two-day">
        <div className="width">
            <div className="two-card">
                <h1>
                3元首充限时送
                </h1>

                {top_task.map(task => <div className="card-index " style={{backgroundImage: 'url(/resources/party/two-card.png)'}} onClick={() => receive_two(task)}>
                    <div className="text">
                        <div className="name">{task.name}</div>
                        <div className="desc">{task.info}</div>
                    </div>
                    <div className='but'>
                        {data ? <>
                        {(()=>{
                            let name = task.taskname;
                            if(datas.day < 2) {
                                return <img src="/resources/party/two-no-start.png" />
                            } else if(data[name] == 0) {
                                return '去完成';
                            } else if(data[name] == 1) {
                                return <img src="/resources/party/two-lq.png"/>;
                            } else if(data[name] == 2) {
                                return  <img src="/resources/party/two-already.png" />;
                            }
                        })()}</>
                        : <img src="/resources/party/two-lq.png"/>}
                    </div>
                </div>)}
            </div>

            <div className="two-task">
                <h1>
                做任务 抵现金
                </h1>
                <div className="tasks">
                    {tasks.map(task => <div className={"task " + task.class } onClick={() => receive_two(task)}>
                        <div className="icon"><img src={'/resources/party/two-' + (task.class) + '.png'} alt=""/></div>
                        <div className="info">
                            <div className="name">
                            {task.name}
                            </div>
                            <div className="desc">{task.reward}</div>
                        </div>
                        <div className={'operate ' + (datas.day < 2 ? 'no-open' : ( data ? data[task.taskname] == 2 ? 'alreadly-lq' : ''  : '' ))} >
                            {data ? <>
                            {(()=>{
                                let name = task.taskname;
                                if(datas.day < 2) {
                                    return '未开启';
                                } else if(data[name] == 0) {
                                    return '去完成';
                                } else if(data[name] == 1) {
                                    return '领取';
                                } else if(data[name] == 2) {
                                    return  '已领取';
                                }
                            })()}</>
                            : '去完成'}
                        </div>
                    </div>)}
                </div>
            </div>
        </div>
    </div>
};