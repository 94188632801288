import general from "../general";
import {LeftOutlined} from "@ant-design/icons";
import React, {useEffect, useRef, useState} from "react";
import KeepScroll from "./KeepScroll";
import {useScroll, useSize, useThrottleFn} from "ahooks";
import {Link, useHistory, useLocation} from "react-router-dom";
import URI from "urijs";
import ActiveContext from "../lib/ActiveContext";
import $ from "jquery";
import { url } from "../env";

export const NavLink = props => {
  const history = useHistory();
  const location = useLocation();
  const [initProps, setInitProps] = useState({});
  const [newProps, setNewProps] = useState({});
  useEffect(() => {
    setInitProps({user: true, token: true, ...props});
  }, [props]);
  useEffect(() => {
    let to = props.to;
    if (to) {
      if (initProps.iframe && initProps.token) {
        to = URI(to)
          .addQuery('username', localStorage.getItem('username'))
          .addQuery('token', localStorage.getItem('token'))
          .toString();
      }
      if (initProps.iframe) {
        let uri = URI('/iframe');
        uri.addQuery('url', to);
        if (initProps.iframe.title) {
          uri.addQuery('title', initProps.iframe.title);
        }
        //判断是否是全屏的标识(仅云游使用)
        if (initProps.iframe.gamefullscreen) {
          uri.addQuery('gamefullscreen', initProps.iframe.gamefullscreen);
        }
        //判断来源是否是平台币
        if (initProps.iframe.sinfo) {
          uri.addQuery('sinfo', initProps.iframe.sinfo);
        }
        to = uri.toString();
      }
    }
    setNewProps({...props, to, iframe: null, user: null, token: null, onClick: null});
  }, [initProps]);
  const onClick = e => {
    if (initProps.user && !general.isLogin()) {
      e.preventDefault();
      e.stopPropagation();
      // history.push(URI('/login').addQuery('callback', location.pathname + location.search + location.hash).toString());
      history.push(URI('/verificode').addQuery('callback', location.pathname + location.search + location.hash).toString());
    } else {
      if (props.onClick) {
        props.onClick(e);
      }
    }
  }

  return props.to ? <Link className={props.className} {...newProps} onClick={onClick}>{props.children}</Link> :
    <a className={props.className} {...newProps} onClick={onClick}>{props.children}</a>;
};

export const NavView = props => {
  const [title, setTitle] = useState(props.title);
  const [colour, setColour] = useState();
  const [operate, setOperate] = useState();

  const scroll = useScroll();
  const {run: _setColour} = useThrottleFn(
    value => {
      setColour(value);
    },
    {wait: 10},
  );
  useEffect(() => {
    if (props.active && props.color) {
      if (scroll.top) {
        let opacity = Math.abs(scroll.top) / 40;
        if (opacity > 1) {
          opacity = 1;
        }
        _setColour('rgba(' + props.color + ', ' + opacity + ')');
      } else {
        _setColour('transparent');
      }
    }
  }, [scroll, props.active]);

  const backRef = useRef();
  const operateRef = useRef();
  const backSize = useSize(backRef);
  const operateSize = useSize(operateRef);

  useEffect(() => {
    if (operateSize.width > backSize.width) {
      if (backRef.current) {
        $(backRef.current).css('width', operateSize.width);
      }
    }
  }, [backSize, operateSize]);

  return <ActiveContext.Provider value={props.active}>
    <KeepScroll active={props.active}>
      <div
        className={'nav-view ' + (props.invasion ? 'invasion ' : '') + (props.active ? 'active' : '') + (props.query.gamefullscreen && props.query.gamefullscreen == 1  ? ' nav-view-roam-screen':'')}
        style={{zIndex: props.index}}
      >
        {/** props.query.gamefullscreen 是否为全屏，1:是， 则隐藏title，撑满整个屏幕 **/}
        <div className={'nav-view-head width ' + props.theme + (props.query.gamefullscreen && props.query.gamefullscreen == 1  ? ' had-roam-screen':'')} style={colour ? {backgroundColor: colour} : null}>
          <div className="container">
            <div className="back" ref={backRef} onClick={() => general.history.goBack()}><LeftOutlined/></div>
            <div className="title">{title}</div>
            {(() => {
              if(props.query.sinfo == 'nisptb') {
                return <div className="operate" ref={operateRef}>
                  <a href="#/user/bill?type=1">平台币记录</a>
                </div>
              } else if (props.query.sinfo == 'hangup') {
                return <NavLink to={(() => {
                  let url = URI('https://api3.app.wakaifu.com/purchasing')
                        .addQuery('title', '购买须知')
                        .toString();
                      return url;
                    })()}
                  iframe={{title: '购买须知'}}
                  user={false}
                ><img src="resources/hangup/notice.png" alt="" width={22} /></NavLink>
              } else {
                return <div className="operate" ref={operateRef}>
                  {operate}
                </div>
              }
            })()}
            {/* <div className="operate" ref={operateRef}>{operate}</div> */}
          </div>
        </div>
        <div className={"nav-view-body" + (props.query.gamefullscreen && props.query.gamefullscreen == 1 ? ' had-roam-screen-body' : '')} style={{background: props.background}}>
          <props.component
            active={props.active}
            action={props.action}
            query={props.query}
            setTitle={setTitle}
            setColour={setColour}
            setOperate={setOperate}
          />
        </div>
      </div>
    </KeepScroll>
  </ActiveContext.Provider>;
};

export default {};