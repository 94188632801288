import {useState} from "react";
import HotRank from "./rank/HotRank";
import NewRank from "./rank/NewRanks";
import DownRank from "./rank/DownRank";
import KeepScroll from "../../lib/KeepScroll";
import useActiveState from "../../lib/useActiveState";

const router = [
  {
    id: 'hotrank',
    component: HotRank,
    title: '热门榜',
  },
  {
    id: 'newrank',
    component: NewRank,
    title: '新游榜',
  },
  {
    id: 'downrank',
    component: DownRank,
    title: '下载榜',
  },
];

export default props => {
  const {aplus_queue} = window;
  const [routerIndex, setRouterIndex] = useState(props.type || props.type === 0 ? props.type : 0);
  const [user] = useActiveState('user');
  const [agent] = useActiveState('agent');

  //排行榜tab埋点
  const clickTabOfTheRankList = (index) => {
    setRouterIndex(index);
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickTabOfTheRankList', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), tabname:router[index].title, agent: agent}]
    });
  }

  return <div className="index-indexrank">
    <div className="indexrank-head width">
      {router.map((item, index) => <div className={'item ' + (index == routerIndex ? 'active' : '')} onClick={() => clickTabOfTheRankList(index)}>
        <div className="text">{item.title}</div>
        <img src="/resources/index/bl.png" className="bl"/>
      </div>)}
    </div>
    <div className="indexrank-body">
      {router.map((item, index) => <div className={'indexrank-body-item ' + (index == routerIndex ? 'active' : '')}>
        <KeepScroll active={props.active && index == routerIndex}>
          <item.component active={props.active && index == routerIndex}/>
        </KeepScroll>
      </div>)}
    </div>
  </div>;
};