import React, {useEffect, useState} from "react";
import general from "../../../general";
import {Link} from "react-router-dom";
import $ from "jquery";
import {Swiper, SwiperSlide} from 'swiper/react';
import SwiperCore, {Pagination} from "swiper";
import {useScroll, useMount, useLockFn} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from "../../../lib/useActiveState";
import Subtitle from "../../components/Subtitle";

SwiperCore.use([Pagination]);

export default ({active, type, setTag}) => {
  const {aplus_queue} = window;
  const [agent] = useActiveState('agent');
  const [user] = useActiveState('user');
  const [banners, setBanners] = useState([]);
  const [gameGroups, setGameGroups] = useState([]);
  const [obsolete, setObsolete] = useState();
  const [gameList, setGameList] = useState([]);
  const [more, setMore] = useState(true);
  const [page, setPage] = useState(1);
  const [init, setInit] = useState();
  const [loading , setLoading] = useState();

  const load = useLockFn(async (parms = {})  => {
    if(loading) {
      return;
    }
    Dialog.loading();
    setLoading(true);
    let tmp = {
      // type: sort,
      game_species_type: type,
      game_classify_type: -1,
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }

    general.axios.post('/base/game/getTypeGames', tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setBanners(data.banner_list);
        setGameGroups(data.game_cate);
        parms.page && setPage(parms.page);
        let {game_list: list, paginated} = data;
        setGameList(gameList => parms.page === 1 || !parms.page ? list : [...gameList, ...list]);
        setMore(paginated.more && list.length > 0);
        setInit(true);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  useMount(() => load());
  useEffect(() => {
    if (active && !init) {
      load({page: 1});
    } else {
      setObsolete(true);
    }
  }, [type]);

  const scroll = useScroll();
  useEffect(() => {
    if (active && init) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, active]);

  //分类游戏点击埋点
  const clickTheGameOfCategories = (item) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickTheGameOfCategories', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), typename: '精选', gamename:item, agent: agent}]
    });
  }
  // console.log(gameList);

  return <div className="game-good">
    {/* <div className="banners">
      <Swiper autoplay={true} pagination={{clickable: true}}>
        {banners.map(banner => <SwiperSlide>
          <Link to={'/game?id=' + banner.game_id} className="banner">
            <div className="img"><Image src={banner.image}/></div>
            <div className="entity">
              <div className="icon"><Image src={banner.game_image.thumb}/></div>
              <div className="text">
                <div className="name">{banner.game_name}</div>
                <div className="info1">
                  {banner.game_classify_type}
                  &nbsp;|&nbsp;
                  {banner.howManyPlay}人在玩
                </div>
                <div className="info2">
                  {banner.game_desc.split('+').map(item => <div className="tag">{item}</div>)}
                </div>
              </div>
            </div>
          </Link>
        </SwiperSlide>)}
      </Swiper>
    </div> */}
    <div className="groups">
      {/* {gameGroups.map(group => <div className="group">
        <div className="head">
          <div className="name">{group.game_classify_name}</div>
          <div className="more" onClick={() => setTag(group.game_classify_id)}>更多 ></div>
        </div>
        <div className="body clearfix">
          {group.game_list.map(game => <Link to={'/game?id=' + game.game_id} className="item">
            <div className="icon">
              <Image src={game.game_image.thumb}/>
              <div className="badge">{game.game_desc.split('+')[1]}</div>
            </div>
            <div className="name">{game.game_name}</div>
          </Link>)}
        </div>
      </div>)} */}

      <div className="items">
      {gameList.map(banner => <>
          {(()=>{
            if(banner.type == 'special') {
              return <Link to={"/game/project?id=" + banner.specialId} className="item special" >
                <div className="entity-special">
                  <div className="data-head">
                    <div className="text">
                      <img src={banner.title_image} />
                    </div>
                    <div className="more">
                      更多<img src="/resources/welfare/left-back.png" className="left-back" />
                    </div>
                  </div>
                  <div className="tips">{banner.tips}</div>
                </div>
                <div className="data-body">
                  <img src={banner.top_image} className="project-img-four"/>
                </div>
              </Link>
            } else if(banner.type == 'game') {
              return <Link to={'/game?id=' + banner.game_id + '&agent=' + agent} className="item" onClick={()=>clickTheGameOfCategories(banner.game_name)}>
                <div className="entity">
                  <div className="icon">
                    <Image src={banner.game_image.thumb}/>
                  </div>
                  <div className="text">
                    <div className="name">
                      {/* {banner.game_name} */}
                      <div className={" " + (banner.nameRemark ? banner.nameRemark.length > 10 ? " name-new-length" : " name-new" : "")}>
                        {banner.game_name}
                      </div>
                    </div>
                    <div className="info1">
                      {/* {
                        banner.nameRemark ?
                        <span className="nameRemark">
                          {banner?.nameRemark}&nbsp;
                        </span>
                        : null
                      } */}
                      <div className="scrole-stat">
                        <img src="/resources/game/score.png" />
                        <span>{game.score}</span>
                      </div>
                      {banner.game_classify_type.trim()}
                      <Subtitle>{banner.nameRemark}</Subtitle>
                    </div>
                    <div className="info2">
                      {/* {banner.game_desc.split('+').map(item => <div className="tag">{item}</div>)} */}
                      {/* {banner.game_desc.split('+').map(item => <div className="tag"> <div className="tagtips"><span className="first-test">{item.slice(0,1)}</span> <span className="other">{item.slice(1)}</span></div> </div>)} */}
                      {/* {banner.game_desc.split('+').map(item => <span className="tag"><span className="tagtips"><span className="first-test">{item.slice(0,1)}</span>{item.slice(1)}</span></span>)} */}
                      {banner.game_desc.split('+').map(item => <span className="tag">{item}</span>)}
                    </div>
                    <div className="info3">
                      {banner.introduction ? <span style={{color:'#888',fontSize:'12px'}}>{banner.introduction}</span> : null}
                    </div>
                  </div>
                </div>
                <div className="img"><Image src={banner.banner_url}/></div>
              </Link>
            }
          })()}
          </>
        )}
      </div>
    </div>
    {gameList.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
    {/* {gameList.length > 0 ? <div className="view-end">别撩啦！已经到底啦~</div> : null} */}
  </div>;
};