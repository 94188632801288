import React, { useEffect, useRef, useState } from 'react'
import './css/Community.css'
import CommunityList from './comments/CommunityList'
import { CSSTransition } from 'react-transition-group'
import { useHistory } from 'react-router-dom'
import Article from './../../Article.js';
import { NavLink } from '../../../../lib/Navigation'
import URI from "urijs";
import ComnunitySort from './comments/ComnunitySort'
import { useScroll } from 'ahooks'
import Dialog from '../../../../lib/Dialog'

export default props => {

  const router = [
    {
      id: 'all',
      component: CommunityList,
      name: '#全部',
      tagid:'-1'
    },
    {
      id: 'comment',
      component: CommunityList,
      name: '#评论',
      tagid:'1'
    },
    {
      id: 'active',
      component: Article,
      name: '#活动',
      tagid:''
    },
    {
      id: 'index',
      component: CommunityList,
      name: '#攻略',
      tagid:'2'
    },
    {
      id: 'question',
      component: CommunityList,
      name: '#问答',
      tagid:'3'
    },
  ]

  const [idx,setIdx] = useState(-1)
  const [isSearch,setIsSearch] = useState(false)
  const [search_info,setSearch_info] = useState('')
  const [alert,setAlert] = useState(false)
  const [sortAlert,setSortAlert] = useState(false)
  const [sort,setSort] = useState('new_time')
  const [top,setTop] = useState(0)
  const inputRef = useRef()
  const history = useHistory()

  const scroll = useScroll()
  useEffect(()=>{
    if (props.active){
      setTop(document.getElementById('sortdiv').getBoundingClientRect().top)
    }
  },[scroll,props.active])

  const searchAction = ()=>{
    const value = inputRef.current.value
    setSearch_info(value)
    setIsSearch(true)
    setTimeout(() => {
      setIsSearch(false)
    }, 100);
  }

  useEffect(()=>{
    if (props.active && idx === -1){
      setIdx(0)
    }
  },[props.active])

  const sortAction = (e)=>{
    setSortAlert(false)
    if (e.sort) {
      setSort(e)
    }
  }
  
  return (
    <div className='community'>
      <div className="community-container">
        
        <div className="search">
          <div className="searchbar">
            <img src="resources/community/search.png" alt="" />
            <input ref={inputRef} type="text" placeholder='搜索热门话题/评论'/>
          </div>
          <div className="searchbtn" onClick={searchAction}>搜索</div>
        </div>

        <div className="type">
          <div className="index">
            {router.map((item,index)=>{
              return (
                <div className='index-container' onClick={()=>setIdx(index)}>
                  <div className={'name ' + (idx === index ? 'active' : '')}>{item.name}</div>
                </div>
              )
            })}
          </div>
          <div id='sortdiv' className="sort" onClick={()=>setSortAlert(!sortAlert)}>
            <span>{sort.title ? sort.title : '最新帖子'}</span>
            <img src="resources/community/sort.png" alt="" />
          </div>
        </div>

        <div className={'community-main'}>
        {router.map((item,index)=>{
          return (
            <div className={'community-main-item ' + (index === idx ? 'active' : '')}>
            {index == 0 && props.game.community_recruit && <NavLink to={(() => {
                let url = URI(props.game.community_recruit.url)
                    .addQuery('topic_id', props.game.maiyou_gameid)
                    .addQuery('title', '社群管理招募公告')
                    .toString();
                  return url;
                })()}
                iframe={{title: '社群管理招募公告'}}
                user={false}
              ><img src={props.game.community_recruit.img} alt="" style={{
                padding:'5px 10px'
              }}/></NavLink>}
              <item.component 
                active={index === idx && props.active === true} 
                topic_id={props.game?.game_info?.maiyou_gameid}
                tagid={item.tagid}
                game={props.game}
                sort={sort.sort}
                search={search_info}
                community={true}
                isSearch={isSearch}></item.component>
            </div>
          )
        })}
        </div>
        <div className="publish" onClick={()=>setAlert(true)}>
          <img src="resources/community/publish.png" alt="" />
        </div>
        <CSSTransition in={alert} timeout={250} unmountOnExit={true}>
          <div className="publish-alert">
            <div className="publish-mask" onClick={()=>setAlert(false)}></div>
            <div className="publish-container">
              <div className="header">
                <NavLink to={URI('https://api3.app.wakaifu.com/communityRewards').toString()}
                  iframe={{title: '奖励规则'}}
                  user={false}
                >
                  <div className="rule">
                    <img src="resources/community/rule.png" alt="" />
                    &nbsp;<span>奖励规则</span>
                  </div>
                </NavLink>
                
                <div className="close" onClick={()=>setAlert(false)}>
                  <img src="resources/community/close.png" alt="" />
                </div>
              </div>
              <div className="content">
                <NavLink to={'/publish?tagid=1&topic_id=' + props.game?.game_info?.maiyou_gameid}>
                  <div className="item" onClick={()=>setAlert(false)}>
                    <img className='icon' src="resources/community/leave_comment.png" alt="" />
                    <img className='logo' src="resources/community/prizes.png" alt="" />
                    <div className="text">发评论</div>
                  </div>
                </NavLink>
                <NavLink 
                  to={props.game?.played ? '/publish?tagid=2&topic_id=' + props.game?.game_info?.maiyou_gameid : ''}
                  onClick={()=>{
                    if (!props.game?.played){
                      Dialog.info('只能玩过该游戏才能发布攻略！')
                    }
                  }}>
                  <div className="item" onClick={()=>setAlert(false)}>
                    <img className='icon' src="resources/community/leave_strategy.png" alt="" />
                    <img className='logo' src="resources/community/prizes.png" alt="" />
                    <div className="text">发攻略</div>
                  </div>
                </NavLink>
                <NavLink to={'/publish?tagid=3&topic_id=' + props.game?.game_info?.maiyou_gameid}>
                  <div className="item" onClick={()=>setAlert(false)}>
                    <img className='icon' src="resources/community/leave_question.png" alt="" />
                    <div className="text">问大神</div>
                  </div>
                </NavLink>
              </div>
              <div className="cancel" onClick={()=>setAlert(false)}>取消</div>
            </div>
          </div>
        </CSSTransition>
      </div>
      {sortAlert ? 
      <ComnunitySort 
        top={top} 
        sortAction={sortAction}
        sortList={[
          {
            title:'最新帖子',
            sort:'new_time'
          },
          {
            title:'最热帖子',
            sort:'hottest'
          },
          {
            title:'最新回复',
            sort:'new_reply'
          }]}
        ></ComnunitySort> : null}
    </div>
  )
}
