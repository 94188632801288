import {Link} from "react-router-dom";
import React, {useEffect, useState} from "react";
import general from "../../../general";
import $ from "jquery";
import {useLockFn, useScroll, useMount} from "ahooks";
import Dialog from "../../../lib/Dialog";
import Image from "../../../lib/Image";
import useActiveState from "../../../lib/useActiveState";
import Subtitle from "../../components/Subtitle";

export default ({active, type, tag}) => {
  const {aplus_queue} = window;
  const [agent] = useActiveState('agent');
  const [user] = useActiveState('user');
  const [page, setPage] = useState(1);
  const [sort, setSort] = useState('def');
  const [games, setGames] = useState([]);
  const [more, setMore] = useState(true);
  const [obsolete, setObsolete] = useState();
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [randomColorInfo] = useActiveState('randomColorInfo');
  const [infoTextColor, setInfoTextColor] = useState();

  const load = useLockFn(async (parms = {}) => {
    if(loading) {
      return;
    }
    Dialog.loading();
    setLoading(true);

    let tmp = {
      tab: sort,
      game_species_type: '',
      game_classify_type: tag,
      pagination: {count: 20, page: page},
    };
    if (parms.page) {
      tmp.pagination.page = parms.page;
    }
    general.axios.post('/base/game/getTypeGames', tmp)
      .finally(() => {
        setLoading(false);
        Dialog.close();
      })
      .then(res => {
        let {status, data} = res.data;
        if (!status.succeed) {
          Dialog.error(status.error_desc);
          return;
        }
        setInfoTextColor(randomColorInfo[parseInt(Math.random() * 4)]);
        setObsolete(false);
        setLoaded(true);
        parms.page && setPage(parms.page);
        let {game_list: list, paginated} = data;
        setGames(games => parms.page === 1 || !parms.page ? list : [...games, ...list]);
        setMore(paginated.more && list.length > 0);
      })
      .catch(err => {
        Dialog.error(err.message);
      });
  });

  useMount(() => load());
  useEffect(() => {
    // console.log(type)
    // console.log("123");
    // console.log(tag);
    // console.log("456");
    // console.log(sort);
    // console.log(active);
    if (active) {
      load({page: 1});
    } else {
      setObsolete(true);
    }
  }, [type, tag, sort]);
  // useEffect(() => {
  //   if (active && obsolete) {
  //     load({page: 1});
  //   }
  // }, [active, obsolete]);

  const scroll = useScroll();
  useEffect(() => {
    if (active) {
      if (scroll.top < -80) {
        load({page: 1});
      }
      if (scroll.top + window.innerHeight + 20 >= $(document).height() && more) {
        load({page: page + 1});
      }
    }
  }, [scroll, active]);

  //分类游戏点击埋点
  const clickTheGameOfCategories = (item) => {
    aplus_queue.push({
      action: 'aplus.record',
      arguments: ['ClickTheGameOfCategories', 'CLK', {age:user?.age, level: user?.vip_level, sex: user.sex == 0 ? '未知' : user.sex == 1 ? '男' : '女', username: localStorage.getItem('username'), typename: '精选', gamename:item, agent: agent}]
    });
  }

  return <div className={'game-type' + (!tag ? ' notag' : '')}>
    <div className="option-head">
      <div className="head">
        {[
          {id: 2, text: '默认排序',tab:'def'},
          {id: 3, text: '最新上架',tab:'new'},
          {id: 1, text: '热门游戏',tab:'hot'}
        ].map(item => <div
          className={'item ' + (sort == item.tab ? 'active' : '')}
          onClick={() => setSort(item.tab)}
        >
          {item.text}
        </div>)}
      </div>
    </div>
    <div className="game-body">
      {games.map((game, i) => <Link to={'/game?id=' + game.game_id + ('&type='+type) + '&agent=' + agent} className="item" onClick={()=>clickTheGameOfCategories(game.game_name)}>
        {(()=>{
          if(sort == 'def' || sort == 'hot') {
            if(i == 0 || i==1 || i == 2) {
              return <div className="index">
                <img src={"/resources/index/new-" + (i + 1) + ".png"} />
              </div>
            }
          }
        })()}
        <div className="icon">
          <Image src={game.game_image.thumb}/>
          {/* <Image src={game.game_image.thumb}/> */}
          {/* {game.discount < 1 ? <div className="discount">{game.discount * 10}折</div> : null} */}
          {
            game.top_lable != '' ?
            <div className="iconlable jx iconlable-font" style={{backgroundImage: 'linear-gradient('+game?.lableColor.t+', '+game?.lableColor.b+')'}}>
              {game.top_lable}
            </div>
            : null
          }

        </div>
        <div className="text">
          <div className={'name ' + (game.discount < 1 ? '' : 'complate')}>
            {/* {game.top_lable != '' ? <span>{game.top_lable }</span> : null }{game.game_name} */}
            <div className={" " + (game.nameRemark ? game.nameRemark.length > 10 ? " name-new-length" :  " name-new" : "")}>
              {/* {game.top_lable != '' ? <span className="span">{game.top_lable }</span> : null } */}
              {game.game_name}
            </div>
          </div>
          <div className="info1">
            {/* {
              game.nameRemark ?
              <span className="nameRemark">
                {game?.nameRemark}&nbsp;
              </span>
              : null
            } */}
            {/* <div className="scrole-stat">
              <img src="/resources/game/score.png" />
              <span>{game.score}</span>
            </div> */}
            {game.game_classify_type.trim()}
            <Subtitle>{game.nameRemark}</Subtitle>
            
            {/* {game.game_classify_type.replace(/(^\s*)|(\s*$)/g, "").replace(/\s+/g, "&")} */}
            {/* {
              game.game_classify_type.split(' ').map(item => item?<span >
                {item}
              </span>:' ')
            } */}

            
          </div>
          {/* <div className="info2"> */}
            {/* {game.game_desc ? game.game_desc.split('+').map(item => <div
              className="tag">{item}</div>) : game.introduction} */}

            {/* {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag"> <div className="tagtips"><span className="first-test">{item.slice(0,1)}</span> <span className="other">{item.slice(1)}</span></div> </div>) : game.introduction} */}
            {/* {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag"> <span className="tagtips"><span className="first-test">{item.slice(0,1)}</span>{item.slice(1)}</span> </div>) : game.introduction} */}
            {/* {game.game_desc ? game.game_desc.split('+').map(item => <div className="tag">{item}</div>) : game.introduction} */}
          {/* </div> */}
          <div className="info3">
            {
              game.bottom_lable ? 
              <>
              {Object.keys(game.bottom_lable).length > 0 ? 
              <div className="bottom-lable">
                <span className="first-text" style={{background:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.left_content}</span><span className="second-text" style={{color:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC',borderColor:game.bottom_lable.type ==1 ? '#FF8C50' : '#9F9DFC'}}>{game.bottom_lable.right_content}</span>
              </div> : null }
              </>
              : null
            }
            {game.exclusive_game_describe?.length > 0 ? <div className="speed">{game.exclusive_game_describe}</div> : null}
            {game.is_support_accele ? <div className="speed">可加速</div> : null}
            {game.is_easy_play ? <div className="speed">省心玩</div> : null}
            {Object.keys(game.bottom_lable).length == 0 && !game.is_support_accele && !game.is_easy_play && game.introduction && game.exclusive_game_describe?.length == 0 ? <span style={{color:'#888'}}>{game.introduction}</span> : null}
          </div>
          {game.discount < 1 ? <div className="discount" ><span>{game.discount * 10}</span>折</div> : null}
        </div>
      </Link>)}
      {games.length > 6 && !more ? <div className="view-end">别撩啦！已经到底啦~</div> : null}
      {games.length === 0 && loaded ? <div className="recovery-empty"><img src="/resources/game/screen_game_empty.png" alt=""/>暂无此类游戏</div> : null}
    </div>
  </div>;
};